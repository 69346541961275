import $ from 'jquery'
import {
  hasCookie,
  setCookie,
  getCookie,
  DEFAULT_PATH
} from '../core/utils/Cookies'
import BaseTemplateModule from '../core/modules/BaseTemplateModule'
import { createQueryObject, buildQueryString } from '../core/utils/query'
import CustomDomEventNames from 'Core/constants/CustomDomEventNames'
import ClickDimensionService from '../services/ClickDimensionService'

const { SCInit, SCRefresh } = window

export default class CADDownload extends BaseTemplateModule {
  static domEvents = () => ({
    'click [data-table-link]': 'onTableLinkClick',
    'click [data-tabs-previous]': 'onPreviousClick',
    'click [data-tabs-next]': 'onNextClick',
    'click [data-download] a': 'onDownloadClick',
    'click [data-download-action]': 'onAlternativeDownloadClick',
    'click [data-dialog-overlay]': 'onDialogOverlayClick',
    'click [data-close-form]': 'onCloseFormClick',
    'click [data-sc-table] a': 'onClickSelectDownload',
    [`${CustomDomEventNames.OnFormSerialized} form`]: 'onFormSerialized'
  })

  cookieKey = 'CADDownload_CookieKey'

  constructor(...args) {
    super(...args)

    if (SCInit) {
      // Need to expose jquery for SCToolbox
      window.jQuery = window.$ = $
      SCInit(this.options.scKey)
      SCSetDownloadMode('manual')
    }

    const $firstTableLink = this.$('[data-table-link]').first()
    const firstTableId = $firstTableLink.data('table-link')
    window.addEventListener('resize', () => {
      const $table = this.$('[data-sc-table]')[0]
      this.hideColumns($table)
    })
    this.activateTable(firstTableId)
  }

  createObserverOfTable() {
    const $table = this.$('[data-sc-table]')[0]
    const mutationObserver = new MutationObserver((mutation, observer) => {
      this.hideColumns($table)
      observer.disconnect()
    }).observe($table, {
      childList: true
    })
  }

  hideColumns(table) {
    //this function is used to override Solid Components inline styling.
    const hideColumn = $element => {
      $element.style.display = 'none'
    }

    const unsetMaxWidth = $element => {
      $element.style.maxWidth = 'unset'
    }

    const setColumnWidth = ($element, columnWidth) => {
      $element.setAttribute('colspan', 1)
      $element.style.minWidth = `${columnWidth}px`
      $element.style.maxWidth = `${columnWidth}px`
      $element.style.width = `${columnWidth}px`
      $element.style.textAlign = 'center'
      $element.style.fontSize = '14px'
    }

    const iconColumn = $element => {
      setColumnWidth($element, 40)
    }

    const smallColumn = $element => {
      setColumnWidth($element, 75)
    }

    const regularColumn = $element => {
      setColumnWidth($element, 90)
    }

    const headerWrapper = table.getElementsByClassName('header_wrapper')[0]
    const headerOuter = table.getElementsByClassName('header_outer')[0]
    const headers = headerWrapper.getElementsByTagName('th')
    const tableWrapper = table.getElementsByClassName('table_wrapper')[0]
    const data = tableWrapper.getElementsByTagName('td')

    unsetMaxWidth(headerWrapper)
    unsetMaxWidth(headerOuter)
    hideColumn(headers[3])

    Array.from(headers).map($element => {
      if (!$element.classList.contains('hoverresponse')) {
        iconColumn($element)
      } else if ($element.hasAttribute('data-tooltip')) {
        smallColumn($element)
      } else {
        regularColumn($element)
      }
    })

    Array.from(data).map($element => {
      if ($element.classList.contains('partno')) {
        regularColumn($element)
      } else if ($element.classList.contains('partdesc')) {
        hideColumn($element)
      } else if (
        $element.classList.contains('pdf') ||
        $element.classList.contains('cad')
      ) {
        iconColumn($element)
      } else {
        smallColumn($element)
      }
    })
  }

  onTableLinkClick(event, $tableLink) {
    const tableId = $tableLink.data('table-link')
    this.activateTable(tableId)
  }

  onPreviousClick() {
    const activeIndex = this.getActiveIndex()
    if (activeIndex === 0) {
      return
    }
    const previousTableId = this.getTableIdByIndex(activeIndex - 1)
    this.activateTable(previousTableId)
  }

  onNextClick() {
    const activeIndex = this.getActiveIndex()
    const tabCount = this.getTabCount()
    if (activeIndex === tabCount - 1) {
      return
    }
    const nextTableId = this.getTableIdByIndex(activeIndex + 1)
    this.activateTable(nextTableId)
  }

  onFormSerialized(event) {
    const serializedForm = event.detail
    if (serializedForm) {
      setCookie(this.cookieKey, serializedForm, Infinity, DEFAULT_PATH)
      this.postFormAndDownloadLink(serializedForm)
    }
  }

  onCloseFormClick() {
    this.closeDialog()
  }

  onAlternativeDownloadClick() {
    this.$('[data-download] a').trigger('click')
  }

  onDownloadClick(event, $downloadLink) {
    const hasFormDialog = this.$('[data-form-dialog]').length !== 0
    if (!hasFormDialog) {
      return
    }

    event.preventDefault()

    this.downloadLink = $downloadLink.prop('href')

    if (this.hasEnteredEmail()) {
      const serializedFormData = this.getFormDataFromCookie()
      this.postFormAndDownloadLink(serializedFormData)
      return
    }

    this.openDialog()
  }

  onClickSelectDownload() {
    this.listenDownloadPreparations()
  }

  setDownloadHiddenFieldToForm(cadFileSrc) {
    const $form = this.$('[data-form]')
    let $cadFileInput = $form.find('[data-cad-file]')

    if ($cadFileInput.length < 1) {
      $cadFileInput = $('<input>')
        .attr({
          type: 'hidden',
          name: 'cadFile',
          'data-cad-file': ''
        })
        .appendTo($form)
    }

    $cadFileInput.val(cadFileSrc)
  }

  listenDownloadPreparations() {
    const downloadActionHideClass = 'cad-download__download-action--show'

    this.$('[data-download-action]').toggleClass(downloadActionHideClass, false)

    const $downloadIconElem = this.$('[data-download]')
    const $downloadIconElemChild = $downloadIconElem.children()[0] || {}

    let previousChildAHref = $downloadIconElemChild.attributes['href']

    let intervalId = window.setInterval(() => {
      const child = $downloadIconElem.children()[0]
      if (!child) return

      if (
        child.tagName === 'A' &&
        child.attributes['href'] !== previousChildAHref
      ) {
        window.clearInterval(intervalId)

        this.setDownloadHiddenFieldToForm(child.attributes.href.value)
        this.$('[data-download-action]').toggleClass(
          downloadActionHideClass,
          true
        )
      }
    }, 50)
  }

  getFormDataFromCookie() {
    return getCookie(this.cookieKey)
  }

  onDialogOverlayClick(event, $dialog) {
    this.closeDialog()
  }

  hasEnteredEmail() {
    return hasCookie(this.cookieKey) && !!getCookie(this.cookieKey)
  }

  postFormAndDownloadLink(serializedFormData) {
    if (!serializedFormData) {
      return
    }

    const queryObject = createQueryObject(serializedFormData)
    queryObject.cadFile = encodeURIComponent(this.downloadLink)

    ClickDimensionService.postForm(buildQueryString(queryObject))

    window.open(this.downloadLink)
    this.closeDialog()
  }

  getActiveIndex() {
    return this.$(`.${this.options.activeTabClass}`).index()
  }

  getTabCount() {
    return this.$('[data-tabs-item]').length
  }

  getTableIdByIndex(index) {
    const $tabItem = this.$(`[data-tabs-item]:nth-child(${index + 1})`)
    const $tableLink = $tabItem.find('[data-table-link]')
    const tableId = $tableLink.data('table-link')
    return tableId
  }

  activateTable(tableId) {
    this.renderTable(tableId)
    this.createObserverOfTable()

    const {
      activeTabClass,
      activeTabIndicatorClass,
      activeArrowClass
    } = this.options

    const $activeTab = this.$(`.${activeTabClass}`)
    const $activeTabIndicator = this.$(`.${activeTabIndicatorClass}`)
    const $nextTab = this.$(`[data-table-link="${tableId}"]`).parent()

    const nextTabIndex = $nextTab.index()
    const $nextTabIndicator = this.$(
      `[data-tabs-indicator-item]:nth-child(${nextTabIndex + 1})`
    )

    $activeTab.removeClass(activeTabClass)
    $activeTabIndicator.removeClass(activeTabIndicatorClass)
    $nextTab.addClass(activeTabClass)
    $nextTabIndicator.addClass(activeTabIndicatorClass)

    const $previousArrow = this.$('[data-tabs-previous]')
    const $nextArrow = this.$('[data-tabs-next]')

    const tabCount = this.getTabCount()

    $previousArrow.toggleClass(activeArrowClass, nextTabIndex !== 0)
    $nextArrow.toggleClass(activeArrowClass, nextTabIndex !== tabCount - 1)
  }

  openDialog() {
    this.$element.addClass(this.options.activeDialogClass)
  }

  closeDialog() {
    this.$element.removeClass(this.options.activeDialogClass)
  }

  renderTable(tableId) {
    this.renderTemplate(
      'scTable',
      {
        _text: tableId
      },
      true
    )

    if (SCRefresh) {
      SCRefresh()
    }
  }
}
